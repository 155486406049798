import React from 'react'

function PrivacyBody() {
    return (
        <div className='bg-body-color'>
            <div className='px-10'>
                <h1 className='text-white font-bold text-2xl py-2'>KATSWIRI PRIVACY POLICY</h1>
                <p className='text-white text-md py-2'>Updated on April 26, 2024</p>

                <h1 className='text-white text-xl font-bold py-2'>1. INTRODUCTION</h1>
                <p className='text-white text-md py-2'>
                    1.1This privacy policy explains how Katswiri (<a className='text-blue-500' href='https://katswiri.com'>katswiri.com</a>)  and its affiliates will gather, use, and maintain your Personal Information on the Katswiri Platform. <br />

                    1.2 It will also explain and help you understand your legal rights regarding your data <br />

                    1.3 When we say ‘’Site’’ we mean <a className='text-blue-500' href='https://katswiri.com'>katswiri.com</a><br />

                    1.4 The ‘’mobile application’’ (Android or iOS) is referred to as The App. <br />

                    1.5 ‘’Services’’ refers to the site, plus any websites, features, applications, widgets, or services controlled by Katswiri. <br />

                    1.6 By using or accessing our website, mobile App, or any other related applications, sites, and services, you acknowledge that you have read and understood this policy. <br />

                    1.7 This policy is bound to change from time to time and the changes are updated on the Katswiri website and the user shall receive a notification about the changes.
                </p>

                <h1 className='text-white text-xl font-bold py-2'>2.2 INFORMATION COLLECTED</h1>
                <p className='text-white text-md py-2'>
                    2.1 You directly provide us with information when you register to our site, use it, complete forms, participate in skilled tests or surveys,
                    or register for any of our programs. This information includes your;
                    <ol className='list-decimal list-inside pl-4 text-white'>
                        <li className='font-bold'>Contact information</li>
                        <ul className='ml-8 list-disc'>
                            <li>First name & last name, email address, postal address, IP Address, & telephone number.</li>
                        </ul>
                        <li className='font-bold'>Identity information</li>
                        <ul className='ml-8 list-disc'>
                            <li>Date of birth & address, Social Media information, National Identification, Passport Number, Driver’s License, criminal records if applicable, & any information that we might need to authenticate the user.</li>
                        </ul>
                        <li className='font-bold'>Billing data</li>
                        <ul className='ml-8 list-disc'>
                            <li>Payment/transaction data, credit or debit card number, expiration date, & security codes, if applicable. </li>
                        </ul>
                        <li className='font-bold'>Employment information</li>
                        <ul className='ml-8 list-disc'>
                            <li>Employment or educational background, Skills, tasks, rates, transcripts, writing samples, and references that are/if necessary, added to a profile.</li>
                        </ul>
                        <li className='font-bold'>Geolocation</li>
                        <ul className='ml-8 list-disc'>
                            <li>Use of technologies such as IP address or GPS, to track the current location of the connected device. </li>
                            <li>A client might use the Katswiri tracker to track taskers’ movements if applicable.</li>
                        </ul>

                        <li>Any other information that is associated with you e.g., your photographs, scores of your surveys, & skill tests.</li>
                    </ol>
                </p>

                <h1 className='text-white text-xl font-bold py-2'>3. USE OF COLLECTED INFORMATION  </h1>
                <p className='text-white text-md py-2'>
                    3.1 To provide you with quality service, improve your user experience & upgrade our security. <br />
                    3.2 To authenticate your identity, to detect fraud, and other misuse of your account. <br />

                    3.3 Make and deliver (via email, SMS, Push Notifications) administrative notices, marketing promotions, and newsletters. <br />

                    3.4 We will contact you through our email <a className='text-blue-500' href='mailto:info@katswiri.com'>info@katswiri.com</a> <br />

                    3.5 Tailor & personalize content to you, maybe surveys, tests, job posts, & other marketing promotional offers. <br />


                    3.6 To connect you & other users to enable the posting, selection, & payment transactions between taskers and clients. <br />

                    3.7 Provide customer support & assist users in cases of complaints, queries, and settling disputes among them. <br />

                    3.8 To promote, advertise our sites, & services with your consent, special offers, promotions, new content, and programs.<br />

                    3.9 Maintain business records & comply with lawful requests by public authorities and comply with applicable laws and regulations.
                </p>

                <h1 className='text-white text-xl font-bold py-2'>4. INFORMATION SHARING AND DISCLOSURE</h1>
                <p className='text-white text-md py-2'>
                    4.1This privacy policy does not allow us to disclose your Data. However, to make the provision of our service possible we may
                    share your information with;
                    <ol className='list-decimal list-inside pl-4 text-white'>
                        <li className='font-bold'>Other Users and Viewers (Our Site Visitors)
                            <ul className='ml-8 list-disc font-normal'>
                                <li>When you create an account, some of the information on your profile is made visible to the public, Username, and Location.</li>
                                <li>Published portfolio, price rate, photos (of the user), & any other information available on the profile.</li>
                                <li>
                                    Sharing this information also helps us to;
                                    <ul><li>Monitor and facilitate your interactions with other users</li></ul>

                                </li>
                            </ul>

                        </li>

                        <li className='font-bold'>Law Enforcement Authorities
                            <ul className='ml-8 font-normal list-disc'>
                                <li>We disclose personal information when it’s required of us to do so by the law. This may help us with services like;</li>
                                <ul>
                                    <li> - Investigation of suspected fraud </li>
                                    <li> - Security breaches</li>
                                    <li> - Law violations</li>
                                    <li> - Policy violations</li>
                                    <li> - Responding to claims against Mmisili   or its affiliates </li>
                                    <li> - Protection of Personal safety, rights, and Assets of our Users, Viewers, clients, and Us ( katswiri )</li>
                                </ul>
                            </ul>
                        </li>

                        <li className='font-bold'>Third parties
                            <ul className='ml-8 font-normal list-disc'>
                                <li>We only share Personal information with third parties for business purposes.</li>
                                <li>The personal information shared, Contact information, Billing information, identity information, and others. </li>
                                <li>We share this information to get the provision of;<ul>
                                    <li>Fraud, and scam detection services.</li>
                                </ul></li>
                            </ul>
                        </li>
                    </ol>
                </p>

                <p className='text-white text-md py-2'> We take legal measures to ensure that third parties adequately handle and safeguard personal data shared with them and follow the obligatory instructions provided by us or this policy. </p>

                <h1 className='text-white text-xl font-bold py-2'>5. RIGHTS TO PERSONAL DATA</h1>
                <p className='text-white text-md py-2'>
                    5.1Users are entitled to rights when it comes to personal data procession.<br />
                    5.2When users exercise their rights in requesting their use and disclosure of personal information, we may authenticate the User’s identity. <br />
                    5.3These are some of the rights and choices our users are entitled to;

                    <ul className='ml-8 list-disc'>
                        <li>The right to withdraw any consent provided to Katswiri at any time.</li>
                        <li>The right to restrict the processing of personal information where certain conditions apply.</li>
                        <li>Users are entitled to have accurate personal information. You can choose to update or correct your Katswiri profile at any time.</li>
                        <li>The right to opt out of direct marketing communications where certain conditions apply.</li>
                        <li>Users are entitled to have their personal information deleted.</li>
                        <li>The right to ask for clarifications regarding their account, request data portability or issue a complaint by contacting us, at info@katswiri.com</li>
                    </ul>
                </p>

                <h1 className='text-white text-xl font-bold py-2'>6. SECURITY</h1>
                <p className='text-white text-md py-2'>
                    6.1Be assured that we take all reasonable steps to safeguard and exhaust all resources in our power to protect the personal information you provide us with. <br />

                    6.2We use safe protocols in communicating or transferring your data using HTTPS and anonymizing your personal information where necessary. <br />

                    6.3 To maintain protection, the user account is password protected and it is only accessed by the user and Katswiri -authorized officials. <br />

                    6.4We always advise users to keep their account password a secret and to sign out and properly close the browser window before any unauthorized access. <br />

                    6.5However, we cannot guarantee the security of the information since communications on the internet are not 100% secure. <br />

                    6.6We promise to notify the suitable authorities when security is breached. The user shall also receive a notification in cases where we feel the security of personal information is not safe. <br />

                    6.7In cases where Users feel like their security has been breached, they can report to us by emailing us at <a className='text-blue-500' href='mailto:info@katswiri.com'>info@katswiri.com</a> or <a className='text-blue-500' href='mailto:info@staticcomputersinc.com'>info@staticcomputersinc.com.</a>
                </p>

                <h1 className='text-white text-xl font-bold py-2'>7. CHILD PROTECTION</h1>
                <p className='text-white text-md py-2'>
                    7.1 Katswili   platform services are made for people who are not under 18 years of age. Minors
                    (under 18 or at least 13 years) are only permitted to access or use this platform with strict supervision from parents or legal guardians. <br />
                    7.2 We do not knowingly collect, or sell personal information of persons under the age of 18. If a
                    legal guardian or parent believes we might have collected information about a child in a manner not applicable to the law please enquire from us at <a className='text-blue-500' href='mailto:info@katswiri.com'>info@katswiri.com</a>
                </p>

                <h1 className='text-white text-xl font-bold py-2'>8. COOKIES</h1>
                <p className='text-white text-md py-2'>
                    8.1 Cookies are small files or text files that web servers send to browsers and that browsers send back to web servers.
                    They contain data or information that can identify your device, browser preferences,   activity, username, password, and other website
                    information/preferences <br />
                    8.2 Cookies and other similar technologies like Pixels, tags, Web beacons, and scripts are used to

                    <ul className='ml-8 list-disc'>
                        <li>Provide you with our services</li>
                        <li>To examine the website operations</li>
                        <li>To improve and personalize your experience with us</li>
                        <li>And for marketing and promotional purposes</li>
                        <li>To authenticate User identity.</li>
                    </ul>
                </p>

                <h1 className='text-white text-xl font-bold py-2'>9. CHANGES TO THIS POLICY</h1>
                <p className='text-white text-md py-2'>
                    katswiri ’s privacy policy is bound to be reviewed and amended from time to time.  These changes might come due to updates, modifications, and expansion of
                    katswiri ’s services. Users will receive notifications of the changes to the policy and we shall update it on our platform.
                </p>

                <h1 className='text-white text-xl font-bold py-2'>10. OUR CONTACT INFORMATION</h1>
                <p className='text-white text-md py-2'>
                    If you have any questions regarding this privacy policy or how we and our affiliates treat and carry out our services please email 
                    our team at <a className='text-blue-500' href='mailto:info@katswiri'>info@katswiri</a> or <a className='text-blue-500' href='mailto:info@staticcomputersinc'>info@staticcomputersinc.com</a> <br />
                    katswiri  staff will gladly assist you within a reasonable time.
                </p>

                <p className='text-white text-md py-2'>Or Mailing us to</p>


                <p className='text-white text-md py-2'>
                    Static Computers Inc <br />
                    Area 47/4 <br />
                    P. O. Box 400 <br />
                    Lilongwe, 3 <br />
                    Tel: <a className='text-blue-500' href='tel:+265211776931'>+265 211 776931</a> <br />
                    Tel: <a className='text-blue-500' href='tel:+265211776931'>+265 211 776931</a><br />
                    Tel: <a className='text-blue-500' href='tel:+265899776931'>+265 899 776931</a> <br />
                    Email: <a className='text-blue-500' href='mailto:info@katswiri'>info@katswiri.com</a> <br />
                </p>

            </div>

        </div>
    )
}

export default PrivacyBody