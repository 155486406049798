
import React from 'react';
import phoneimg2 from '../assets/images/iphone.png'


function Column2() {
    return (
        <div className='container mx-auto md:mt-8 md:py-5'>
            <div className="md:flex mb-8 items-center justify-center">
                <div className="md:w-1/2 text-center justify-start md:text-right">
                    <h1 className="text-3xl md:text-4xl font-bold mb-2 text-gray-900">Readily Available</h1>
                    <p className="text-sm md:text-base mb-4 text-gray-900">Need it done immediately? Dial 6349 Now to speak, <br /> with an expert who is ready to solve your problem</p>
                    <button className="bg-custom-color hover:bg-gray-900 text-white py-2 px-4 rounded-full ml-0">Try it Yourself</button>
                </div>
                <div className="md:w-1/2 py-5 pl-24 md:px-8">
                    <img src={phoneimg2} alt="Image" className="w-3/4 md:w-9/20 h-auto pr-8 md:px-10" />
                </div>




            </div>
        </div>
    );
}

export default Column2;
