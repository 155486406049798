import React from 'react'
import Logo from '../assets/images/LOGO.png'
import AppStore from '../assets/images/App-Store.png'
import PlayStore from '../assets/images/Play-Store .png'
import { MdPhone } from 'react-icons/md';
import { AiOutlineMail } from 'react-icons/ai';
import { BiMapPin } from 'react-icons/bi';
import { FaFacebook, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { Link } from 'react-router-dom';


export default function
    () {
    return (
        <div className='bg-custom-color mt-10'>
            <div className='flex flex-col md:flex-row justify-center items-center px-4'>
                <div className="bg-white flex items-center mt-5 justify-between rounded-full p-1 md:p-2 md:w-2/5">
                    <input
                        type="email"
                        className="text-gray-900 bg-transparent border-none focus:outline-none mr-2"
                        placeholder="Enter Your Email"
                    />
                    <button className="bg-custom-color hover:bg-gray-900 text-white text-sm md:py-2 md:px-3 rounded-full">
                        Subscribe for Updates
                    </button>
                </div>
            </div>

            {/* Left Side */}
            <div className='flex'>

                <div className='w-1/4 hidden md:block px-10 pb-10'>
                    <div>
                        <img src={Logo} className="h-auto"></img>
                    </div>
                    <div className='flex p-5'>
                        <a href='#' className='w-1/10 mr-5'> <img src={AppStore} alt="Image" className=" h-auto" /> </a>
                        <a href='#' className='w-1/10'> <img src={PlayStore} alt="Image" className="h-auto" /> </a>
                    </div>

                </div>

                {/* Navigation */}
                <div className='w-full md:flex'>
    <div className='w-full md:w-1/2'>
    <nav className="flex justify-center items-center px-6">
    <ul className="flex md:flex-row py-2 justify-center items-center flex-grow">
        <li className='md:py-2 pr-1 mr-1 text-sm md:text-md font-bold md:px-4 border-r md:border-none'><a href="#" className="text-white">Home</a></li>
        <li className='md:py-2 pr-1 mr-1 text-sm md:text-md font-bold md:px-4 border-r md:border-none'>
            {/* Container for "Services" item and additional list */}
            <div className="relative">
                <a href="#" className="text-white">Services</a>
                {/* Additional List */}
                <ul className="absolute w-40 left-0 mt-2 hidden md:block rounded-md text-left">
                    <li><a href="#" className="text-white text-sm">ICT</a></li>
                    <li><a href="#" className="text-white text-sm">Media and Graphics</a></li>
                    <li><a href="#" className="text-white text-sm">Mechanical and Electricals</a></li>
                    <li><a href="#" className="text-white text-sm">LifeStyle</a></li>
                    <li><a href="#" className="text-white text-sm">Other Services</a></li>
                </ul>
            </div>
        </li>
        <li className='md:py-2 pr-1 mr-1 text-sm md:text-md font-bold md:px-4 border-r md:border-none'><Link to={"/terms-of-use"} className="text-white"> Terms of Use </Link> </li>
        <li className='md:py-2 pr-1 mr-1 text-sm md:text-md font-bold md:px-4 border-r md:border-none'><Link to={"/privacy-policy"} className="text-white"> Privacy Policy </Link></li>
        <li className='md:py-2 pr-1 mr-1 text-sm md:text-md font-bold md:px-4'><a href="#" className="text-white">Help</a></li>
    </ul>
</nav>

    </div>

    {/* Right Side */}
    <div className='w-full pl-8 md:pl-60 md:w-1/2 md:ml-1'>
        <div className='md:ml-1'>
            <p className='text-xl md:text-xl mb-2 text-white font-bold md:text-left'>Contact</p>
            <p className='text-sm md:text-md mb-2 text-white text-left flex'><MdPhone size={18} className='mr-2' color="white" />+265 999 776 931 / +265 888 776 931</p>
            <p className='text-sm md:text-md mb-2 text-white text-left flex'> <AiOutlineMail size={18} className="text-white mr-2" />info@Katswiri.com</p>
            <p className='text-sm md:text-md mb-2 text-white text-left flex'><BiMapPin size={18} className="text-white mr-2" />Area 47, Sector 4</p>
        </div>

        <div className="flex py-5 md:justify-start items-center">
            <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer" className="text-white mx-2">
                <FaFacebook size={24} />
            </a>
            <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer" className="mx-2 text-white">
                <FaLinkedin size={24} />
            </a>
            <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer" className="mx-2 text-white">
                <FaTwitter size={24} />
            </a>
        </div>
    </div>
</div>

                </div>


            </div>
    )
}
