import React from 'react'
import compimg from '../assets/images/computer-board.png'
import electrical from '../assets/images/electrical.png'
import bicycle from '../assets/images/bicycle.png'
import sketchbook from '../assets/images/sketchbook.png'

export default function
    () {
    return (
        <div className='border text-center border-custom-color mx-4 md:mx-10 rounded-3xl'>
    <h1 className='text-3xl md:text-4xl font-bold mb-2 text-custom-color'>Services</h1>
    <p className='text-xl md:text-4xl mb-2 text-custom-color'>Some of The Popular <span className='font-bold'>Services</span></p>

    <div className='flex flex-col md:flex-row mx-2 md:mx-10 my-8'>

        <div className="w-full md:w-1/2 bg-custom-color p-2 my-2 md:my-0 mx-auto md:mx-2 rounded-xl">
            <img src={compimg} className="h-auto pl-20 md:px-5"></img>
            <h3 className='text-xl md:text-xl font-bold text-white py-4'>ICT and Tech</h3>
            <p className='text-md md:text-sm mb-2 text-white md:text-left'>Phone Repair . Software Development <br /> IT Support . Computer Repair</p>
            <a href='#' className='text-white flex items-start pt-2'>Learn More</a>
        </div>

        <div className="w-full md:w-1/2 bg-custom-color p-2 my-2 md:my-0 mx-auto md:mx-2 rounded-xl">
            <img src={sketchbook} className="h-auto pl-24 md:pl-10"></img>
            <h3 className='text-xl md:text-xl font-bold mb-2 text-white py-4'>Media and Graphics</h3>
            <p className='text-md md:text-sm mb-2 text-white md:text-left'>Video Editing . ADS . Commercials <br /> Logo Design . Animation</p>
            <a href='#' className='text-white flex items-start pt-2'>Learn More</a>
        </div>

        <div className="w-full md:w-1/2 bg-custom-color p-2 my-2 md:my-0 mx-auto md:mx-2 rounded-xl">
            <img src={electrical} className="h-auto pl-24 md:pl-10"></img>
            <h3 className='text-xl md:text-xl font-bold mb-2 text-white py-4'>Mechanical and Electricals</h3>
            <p className='text-md md:text-sm mb-2 text-white md:text-left'>Car Repair . Maintenance Services <br /> Electrical Work . Electrical Installation</p>
            <a href='#' className='text-white flex items-start pt-2'>Learn More</a>
        </div>

        <div className="w-full md:w-1/2 bg-custom-color p-2 my-2 md:my-0 mx-auto md:mx-2 rounded-xl">
            <img src={bicycle} className="h-auto pl-32 md:pl-20"></img>
            <h3 className='text-xl md:text-xl font-bold mb-2 text-white py-4'>LifeStyle</h3>
            <p className='text-md md:text-sm mb-2 text-white md:text-left'>Physical Fitness Doctor . Spiritual Coach <br /> Social Wellness Coach</p>
            <a href='#' className='text-white flex items-start pt-5'>Learn More</a>
        </div>

    </div>
</div>

    )
}
