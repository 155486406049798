import React from 'react'

export default function
    () {
    return (
        <div className='mx-2 md:mx-24 my-8'>
    <div className='flex flex-col md:flex-row mb-10'>
        <div className='width-full text-center md:w-3/20 bg-custom-color rounded-xl'>
            <h1 className='text-white font-bold md:text-6xl md:p-8'>1</h1>
        </div>

        <div className='width-full md:w-17/20 bg-transparent md:mx-8 rounded-xl flex-grow'>
            <h1 className='text-custom-color font-bold text-xl text-center md:text-left md:text-xl'>How To Start Using Katswiri</h1>
            <p className='md:text-xl text-custom-color'>
                Kanema Online is an online video streaming platform, streaming through website www.kanemaonline.com
                and Mobile Applications; Android and iOS. Kanema Online streams Live TVs, Live Events and Videos; Movies,
                Series, Comedies, Documentaries, TV Shows and etc. Viewers are able to Stream and watch Live,
                and Catchup their favorite TV programs and videos
            </p>
        </div>
    </div>

    <div className='flex flex-col md:flex-row mb-10'>
        <div className='width-full text-center md:w-3/20 bg-custom-color rounded-xl'>
            <h1 className='text-white font-bold md:text-6xl md:p-8'>2</h1>
        </div>

        <div className='width-full md:w-17/20 bg-transparent md:mx-8 rounded-xl flex-grow'>
            <h1 className='text-custom-color font-bold text-xl text-center md:text-left md:text-xl'>Call Center</h1>
            <p className='md:text-xl text-custom-color'>
                Kanema Online is an online video streaming platform, streaming through website www.kanemaonline.com
                and Mobile Applications; Android and iOS. Kanema Online streams Live TVs, Live Events and Videos; Movies,
                Series, Comedies, Documentaries, TV Shows and etc. Viewers are able to Stream and watch Live,
                and Catchup their favorite TV programs and videos
            </p>
        </div>
    </div>

    <div className='flex flex-col md:flex-row mb-10'>
        <div className='width-full text-center md:w-3/20 bg-custom-color rounded-xl'>
            <h1 className='text-white font-bold md:text-6xl md:p-8'>3</h1>
        </div>

        <div className='width-full md:w-17/20 bg-transparent md:mx-8 rounded-xl flex-grow'>
            <h1 className='text-custom-color font-bold text-xl text-center md:text-left md:text-xl'>Creating a Seller Account</h1>
            <p className='md:text-xl text-custom-color'>
                Kanema Online is an online video streaming platform, streaming through website www.kanemaonline.com
                and Mobile Applications; Android and iOS. Kanema Online streams Live TVs, Live Events and Videos; Movies,
                Series, Comedies, Documentaries, TV Shows and etc. Viewers are able to Stream and watch Live,
                and Catchup their favorite TV programs and videos
            </p>
        </div>
    </div>

    <div className='flex flex-col md:flex-row mb-10'>
        <div className='width-full text-center md:w-3/20 bg-custom-color rounded-xl'>
            <h1 className='text-white font-bold md:text-6xl md:p-8'>4</h1>
        </div>

        <div className='width-full md:w-17/20 bg-transparent md:mx-8 rounded-xl flex-grow'>
            <h1 className='text-custom-color font-bold text-xl text-center md:text-left md:text-xl'>Ordering a Service</h1>
            <p className='md:text-xl text-custom-color'>
                Kanema Online is an online video streaming platform, streaming through website www.kanemaonline.com
                and Mobile Applications; Android and iOS. Kanema Online streams Live TVs, Live Events and Videos; Movies,
                Series, Comedies, Documentaries, TV Shows and etc. Viewers are able to Stream and watch Live,
                and Catchup their favorite TV programs and videos
            </p>
        </div>
    </div>

    <div className='flex flex-col md:flex-row mb-10'>
        <div className='width-full text-center md:w-3/20 bg-custom-color rounded-xl'>
            <h1 className='text-white font-bold md:text-6xl md:p-8'>5</h1>
        </div>

        <div className='width-full md:w-17/20 bg-transparent md:mx-8 rounded-xl flex-grow'>
            <h1 className='text-custom-color font-bold text-xl text-center md:text-left md:text-xl'>Paying for Services</h1>
            <p className='md:text-xl text-custom-color'>
                Kanema Online is an online video streaming platform, streaming through website www.kanemaonline.com
                and Mobile Applications; Android and iOS. Kanema Online streams Live TVs, Live Events and Videos; Movies,
                Series, Comedies, Documentaries, TV Shows and etc. Viewers are able to Stream and watch Live,
                and Catchup their favorite TV programs and videos
            </p>
        </div>
    </div>

    <div className='flex flex-col md:flex-row mb-10'>
        <div className='width-full text-center md:w-3/20 bg-custom-color rounded-xl'>
            <h1 className='text-white font-bold md:text-6xl md:p-8'>6</h1>
        </div>

        <div className='width-full md:w-17/20 bg-transparent md:mx-8 rounded-xl flex-grow'>
            <h1 className='text-custom-color font-bold text-xl text-center md:text-left md:text-xl'>Creating a Gig</h1>
            <p className='md:text-xl text-custom-color'>
                Kanema Online is an online video streaming platform, streaming through website www.kanemaonline.com
                and Mobile Applications; Android and iOS. Kanema Online streams Live TVs, Live Events and Videos; Movies,
                Series, Comedies, Documentaries, TV Shows and etc. Viewers are able to Stream and watch Live,
                and Catchup their favorite TV programs and videos
            </p>
        </div>
    </div>
</div>

    )
}
