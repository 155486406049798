import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export default function NavBar() {
    const [scrolling, setScrolling] = useState(false);
    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setScrolling(true);
            } else {
                setScrolling(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    return (
        <nav className={`fixed top-0 left-0 w-full ${scrolling ? 'bg-custom-color bg-opacity-90' : 'bg-transparent'} z-10 flex justify-between items-center px-6 py-8 sm:px-8 sm:py-6 md:py-8 lg:px-10 lg:py-8`}>
            {/* Logo and brand name */}
            <div className="flex items-center">
                <span className={`${scrolling ? 'text-white' : 'text-custom-color'} text-bold text-lg`}>Katswiri</span>
            </div>
            
            {/* Mobile menu button (hidden on large screens) */}
            <div className="flex sm:hidden">
                <button onClick={toggleMenu} className={`${scrolling ? 'text-white' : 'text-custom-color'} hover:text-gray-900 focus:outline-none`}>
                    {showMenu ? (
                        <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                        </svg>
                    ) : (
                        <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
                        </svg>
                    )}
                </button>
            </div>
            
            {/* Navigation links and buttons */}
            <ul className={`${showMenu ? 'block' : 'hidden'} sm:flex sm:justify-center sm:items-center sm:flex-grow`}>
                <li className='py-2 px-4'><a href="#" className={`${scrolling ? 'text-white' : 'text-custom-color'} text-bold text-lg`}>Services</a></li>
                <li className='py-2 px-4'><Link to="/how-it-works" className={`${scrolling ? 'text-white' : 'text-custom-color'} text-bold text-lg`}>How Katswiri Works</Link></li>
                
                {/* Buttons */}
                <li className='py-2 px-4'>
                    <button className={`${scrolling ? 'bg-white text-custom-color' : 'bg-custom-color text-white'} hover:bg-gray-900 text-sm py-2 px-4 rounded-full`}>Become Katswiri</button>
                </li>
                <li className='py-2 px-4'>
                    <button className={`${scrolling ? 'bg-transparent text-white border border-white' : 'bg-transparent text-custom-color border border-custom-color'} hover:bg-gray-900 py-2 px-4 rounded-full`}>Sign In</button>
                </li>
            </ul>
        </nav>
    );
}
