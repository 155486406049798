import React from 'react'
import phoneimg from '../assets/images/phone.png'
import AppStore from '../assets/images/App-Store.png'
import PlayStore from '../assets/images/Play-Store .png'

export default function
    () {
    return (
<div className='bg-custom-color my-10 px-4 md:px-10'>
    <div className='flex flex-col md:flex-row items-center'>
        <div className='md:w-1/2 text-white md:px-0 mb-1 md:mb-0'>
            <div className='bg-white w-1/2 ml-24 mt-4 md:-mt-4 md:w-3/4 rounded-lg mb-2 border-2 border-custom-color'>
                <img src={phoneimg} alt="Image" className="w-full h-auto md:w-auto md:h-full" />
            </div>
        </div>

        <div className='md:w-1/2 text-white px-4 md:px-10 text-center md:text-left'>
            <h1 className="text-3xl md:text-6xl font-bold mb-2 text-white pt-8">Get The Services. <br /> From Your Phone</h1>
            <p className='text-xl md:text-xl mb-2 text-white'>Available in the App Store and Google Play</p>
            <div className='flex justify-center mb-4 md:justify-start'>
                <a href='#' className='pr-5'><img src={AppStore} alt="Image" className="h-auto" /></a>
                <a href='#' className='pl-5'><img src={PlayStore} alt="Image" className="h-auto" /></a>
            </div>
        </div>
    </div>
</div>

    )
}
