import React from 'react';
import heroimg from '../assets/images/guy.png';
import NavBar from './nav';
import { MdPhone } from 'react-icons/md';


function Hero() {
    return (
        <div className="bg-hero-color text-white pt-20 px-10 md:px-12 lg:px-20 max-w-8xl rounded-2xl items-center mx-4 mt-6">

            <NavBar />

            {/* Hero section */}
            <div className="container mx-auto flex flex-col md:flex-row items-end justify-center">
                {/* Left column with text */}
                <div className="md:w-11/20 md:pr-4 pb-10">
                    <div className="text-center md:text-left">
                        <h1 className="text-3xl md:text-6xl font-bold mb-4 md:mb-8 text-custom-color">You Have a Problem? <br /> We'll Help You Fix it</h1>
                        <p className="text-sm md:text-xl mb-4 md:mb-8 text-gray-900">Our Support Center is Always Available For Assistance</p>
                    </div>
                    <div className="bg-white text-sm flex items-center justify-between rounded-full p-1">
                        <input
                            type="text"
                            className="text-gray-900 bg-transparent border-none focus:outline-none pl-6 pr-2 md:pr-6 w-full" // Added pr-2 and w-full
                            placeholder="What Service Are you Looking For"
                        />

                        <button className="bg-custom-color hover:bg-gray-900 text-white text-sm md:text-base font-bold py-2 md:py-3 px-6 md:px-12 flex items-center rounded-full">
                            <MdPhone className='mr-2' size={25} color="white" />
                            Call Center
                        </button>
                    </div>

                </div>
                {/* Right column with image */}
                <div className="md:w-9/20 md:pl-4 pb-0 md:pt-10">
                    <img src={heroimg} alt="Your Image" className="rounded-lg shadow-lg w-4/5 h-auto" />
                </div>
            </div>
        </div>
    );
}

export default Hero;
