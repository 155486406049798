import React from 'react'
import Terms_Privacy_Nav from '../components/Terms_Privacy_Nav'
import TermsBody from '../components/terms_body'
import Footer from '../components/Footer';


function TermsOfUse() {
  return (
    <div>
    <Terms_Privacy_Nav />
    <TermsBody />
    <Footer />
    </div>
  )
}

export default TermsOfUse