import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { IoChevronBack } from 'react-icons/io5';
import { MdArrowForward } from 'react-icons/md';

export default function NavBar2() {
    const [showMenu, setShowMenu] = useState(false);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    return (
        <div className="px-10 md:px-12 lg:px-20 max-w-8xl rounded-2xl items-center">
            <nav className="fixed top-0 left-0 w-full bg-custom-color z-10 flex justify-between mt-8 items-center px-6 py-2 sm:px-8 sm:py-6 md:py-8 lg:px-10 lg:py-8">
                {/* Logo and brand name */}
                <div className="flex items-center">
                    <span className="text-white font-bold text-lg">Katswiri.help</span>
                </div>

                {/* Mobile menu button (hidden on large screens) */}
                <div className="flex sm:hidden">
                    <button onClick={toggleMenu} className="text-white hover:text-gray-900 focus:outline-none">
                        {showMenu ? (
                            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                            </svg>
                        ) : (
                            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
                            </svg>
                        )}
                    </button>
                </div>

                {/* Navigation links and buttons */}
                <ul className={`${showMenu ? 'block' : 'hidden'} sm:flex sm:justify-center sm:items-center sm:flex-grow`}>
                    <li className='py-2 px-4'><Link to="/" className="text-white font-bold text-lg flex items-center"><IoChevronBack className='font-bold' size={20} color='white' />Go to Home</Link></li>
                    
                    {/* Buttons */}
                    <li className='py-2 px-4'>
                        <button className="bg-white text-custom-color hover:bg-gray-900 py-2 px-4 rounded-full">Become a Katswiri</button>
                    </li>
                    <li className='py-2 px-4'>
                        <button className="bg-transparent text-white border border-white hover:bg-gray-900 py-2 px-4 rounded-full">Sign In</button>
                    </li>
                </ul>
            </nav>
        </div>
    );
}
