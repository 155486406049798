import React from 'react'
import phoneimg from '../assets/images/iphone1.png';

export default function Column4() {
    return (
        <div className='container mx-auto md:mt-8 md:py-5'>
            <div className="md:flex mb-8 items-center">
                <div className="w-full md:w-1/2 md:text-right">
                    <h1 className="text-3xl text-center md:text-4xl font-bold mb-2 text-gray-900">Earn With Us</h1>
                    <div className='md:pl-60'>
                    <p className="text-sm md:text-base mb-4 text-gray-900 text-center md:text-left md:pl-10">
                        Katswiri empowers you to connect with customers <br />
                        across the nation, delivering impactful experiences <br />
                        at scale. From showcasing your products and <br />
                        services to boosting sales and cultivating strong <br />
                        relationships, Katswiri helps you achieve your <br />
                        business goals efficiently and effectively.
                    </p>
                    </div>
                    <div className='md:flex text-center md:justify-end'>
                    <button className="bg-custom-color hover:bg-gray-900 text-white py-2 px-4 rounded-full mr-1 md:mr-5">Become a Katswiri</button>
                    <button className="bg-transparent text-blue-800 hover:bg-gray-900 py-2 px-4 rounded-full ml-1 md:ml-5 border border-gray-800">How Does It Work?</button>
                    </div>
                </div>

                <div className="w-3/4 md:w-1/2 pl-20 pt-5">
                    <img src={phoneimg} alt="Image" className="w-9/20 h-auto px-10" />
                </div>




            </div>
        </div>
    )
}
