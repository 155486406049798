import React from 'react'
import Terms_Privacy_Nav from '../components/Terms_Privacy_Nav'
import Footer from '../components/Footer';
import PrivacyBody from '../components/privacy_body';


function PrivacyPolicy() {
  return (
    <div>
    <Terms_Privacy_Nav />
    <PrivacyBody />
    <Footer />
    </div>
  )
}

export default PrivacyPolicy