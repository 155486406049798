import React from 'react';
import phoneimg from '../assets/images/Group.png';

function Column1() {
    return (
        <div className='container mx-auto md:mt-8 py-5'>
            <div className="md:flex mb-8 items-center md:justify-between">
                <div className="md:w-1/2 mb-4 md:mb-0 md:mr-4">
                    <img src={phoneimg} alt="Image" className="w-3/4 pl-10 h-auto" />
                </div>
                <div className="md:w-1/2 text-center md:text-left">
                    <h1 className="text-3xl md:text-4xl font-bold mb-2 text-gray-900">We've got what <br /> you are looking for</h1>
                    <p className="text-sm md:text-base mb-4 text-gray-900">We offer a wide range of services tailored to meet your needs, ensuring satisfaction and excellence</p>
                    <button className="bg-custom-color hover:bg-gray-900 text-white py-2 px-4 rounded-full">Take a Look</button>
                </div>
            </div>
        </div>
    );
}

export default Column1;
